import { useState } from "react";
import Paneles from "./Paneles";

import "../sass/main.scss";

import OrganizadorCSI from "../img/CSI.svg";
import OrganizadorObserva from "../img/Observa.svg";
import OrganizadorPorPartes from "../img/PorPartes.svg";
import OrganizadorAntesDespues from "../img/AntesDespues.svg";
import OrganizadorVen from "../img/Venn.svg";
import OrganizadorVPSM from "../img/Veo_pienso.svg";
import Organizador02 from "../img/Organizador_Grafico.svg";

const datosPaneles = {
	csi: {
		id:"csi",
		imagen: OrganizadorCSI,
		name: "CSI",
	},
	observa: {
		id:"observa",
		imagen: OrganizadorObserva,
		name: "Observa y diferencia",
	},
	porpartes: {
		id:"porpartes",
		imagen: OrganizadorPorPartes,
		name: "Completo y por partes",
	},
	antesdespues: {
		id:"antesdespues",
		imagen: OrganizadorAntesDespues,
		name: "3, 2, 1, puente",
	},
	ven: {
		id:"ven",
		imagen: OrganizadorVen,
		name: "Diagrama de Venn",
	},
	vpsm: {
		id:"vpsm",
		imagen: OrganizadorVPSM,
		name: "Veo..., pienso, siento, me pregunto",
	},
	general: {
		id:"general",
		imagen: Organizador02,
		name: "Organizador de proyectos",
	},
};

export default function App() {
	const [panelSeleccionado, setPanelSeleccionado] = useState(null);
	const [panelesModificados, setPanelesModificados] = useState([]);
	const [panelAbierto, setPanelAbierto] = useState(false);

	const cerrarPanel = function () {
		setPanelAbierto(false);
		setPanelSeleccionado(null);
	};

	const abrirPanel = function (panel) {
		return function (evt) {
			setPanelAbierto(true);
			setPanelSeleccionado(panel);
		};
	};

	return (
		<div id="panelPaneles">
			<div className="contenedorMenu">
				<div className="miniatura" onClick={abrirPanel("general")}>
					<img src={Organizador02} />
					<div className="contenedorTitulo">
						<span>{datosPaneles["general"].name}</span>
					</div>
				</div>
				<div className="miniatura" onClick={abrirPanel("csi")}>
					<img src={OrganizadorCSI} />
					<div className="contenedorTitulo">
						<span>{datosPaneles["csi"].name}</span>
					</div>
				</div>
				<div className="miniatura" onClick={abrirPanel("observa")}>
					<img src={OrganizadorObserva} />
					<div className="contenedorTitulo">
						<span>{datosPaneles["observa"].name}</span>
					</div>
				</div>
				<div className="miniatura" onClick={abrirPanel("porpartes")}>
					<img src={OrganizadorPorPartes} />
					<div className="contenedorTitulo">
						<span>{datosPaneles["porpartes"].name}</span>
					</div>
				</div>
				<div className="miniatura" onClick={abrirPanel("antesdespues")}>
					<img src={OrganizadorAntesDespues} />
					<div className="contenedorTitulo">
						<span>{datosPaneles["antesdespues"].name}</span>
					</div>
				</div>
				<div className="miniatura" onClick={abrirPanel("ven")}>
					<img src={OrganizadorVen} />
					<div className="contenedorTitulo">
						<span>{datosPaneles["ven"].name}</span>
					</div>
				</div>
				<div className="miniatura" onClick={abrirPanel("vpsm")}>
					<img src={OrganizadorVPSM} />
					<div className="contenedorTitulo">
						<span>{datosPaneles["vpsm"].name}</span>
					</div>
				</div>
			</div>


			{panelAbierto && (
				<ModalPanel shouldBeOpen={panelAbierto} closeBtnHandler={cerrarPanel} datosPaneles={datosPaneles[panelSeleccionado]}/>
			)}
		</div>
	);
}

function ModalPanel({ datosPaneles, shouldBeOpen, closeBtnHandler, eliminarPanelArray }) {
	const estilosPrincipales = {
		display: shouldBeOpen ? "block" : "none",
	};

	return (
		<div className="fondoModales" onClick={closeBtnHandler}>
			<div
				className="seleccionablePaneles"
				style={estilosPrincipales}
				onClick={(ev) => {
					ev.stopPropagation();
				}}
			>
				<Paneles datosPaneles={datosPaneles} closeBtnHandler={closeBtnHandler} eliminarPanelArray={eliminarPanelArray}/>
			</div>
		</div>
	);
}
