import {useState, useRef, useEffect} from "react";

function PizarraSimple({
    currentAction = "DRAW",
    drawingColor = "#000",
    onChange = () => {},
    value = null
}) {

    const wrapperRef = useRef();
    const canvasRef = useRef();
    const contextRef = useRef();
    const [isDrawing, setIsDrawing] = useState(false);
    const [canvasPosition, setCanvasPosition] = useState(null);

    useEffect(()=>{
        if (typeof contextRef.current !== "undefined") {
            if (currentAction=="ERASER") {
                contextRef.current.globalCompositeOperation = "destination-out";
                contextRef.current.lineWidth = 10;
            } else {
                contextRef.current.globalCompositeOperation = "source-over";
                contextRef.current.lineWidth = 2;
            }
        }
    }, [currentAction]);

    useEffect(()=>{
        if (typeof contextRef.current !== "undefined") {
            contextRef.current.strokeStyle = drawingColor;
        }
    }, [drawingColor]);

    useEffect(() => {
        const canvas = canvasRef.current;
        const wrapper = wrapperRef.current;

        canvas.width = wrapper.clientWidth;
        canvas.height = wrapper.clientHeight;

        const context = canvas.getContext("2d");
        context.lineCap = "round";
        context.strokeStyle = drawingColor;
        context.lineWidth = 2;
        contextRef.current = context;

        const onDocumentMouseUpSetIsDrawingToFalse = function(e){
            setIsDrawing(false)
        }
        document.addEventListener("mouseup",onDocumentMouseUpSetIsDrawingToFalse)

        const {left,top} = canvas.getBoundingClientRect();
        setCanvasPosition({left,top})

        return function cleanup(){
            document.removeEventListener("mouseup", onDocumentMouseUpSetIsDrawingToFalse)
        }
    }, [wrapperRef.current]);

    useEffect(() => {
        const theImageHasChanged = (value!==canvasRef.current.toDataURL())
        if(theImageHasChanged) {
            contextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
            if (value !== null) {
                let img = new Image();
                img.onload = function(){
                    contextRef.current.drawImage(img, 0, 0);
                }
                img.src = value;
            }
        }
    }, [value]);

    const onMouseUp = () => {
        contextRef.current.closePath();
        setIsDrawing(false);
        onChange(canvasRef.current.toDataURL());
    };

    const onMouseEnter = ({nativeEvent}) => {
        if (!isDrawing) return false;
        const { clientX, clientY } = nativeEvent;

        contextRef.current.beginPath();
        contextRef.current.moveTo(clientX - canvasPosition.left, clientY - canvasPosition.top);
    }

    function startDrawing ({ nativeEvent }) {
        const {x,y} = getClickOrTouchPositionRelativeToCanvas(nativeEvent);

        contextRef.current.beginPath();
        contextRef.current.moveTo(x, y);
        setIsDrawing(true);
    }

    function draw ({ nativeEvent }) {

        if (!isDrawing) {
            return false;
        }

        const {x,y} = getClickOrTouchPositionRelativeToCanvas(nativeEvent);

        contextRef.current.lineTo(x, y);
        contextRef.current.stroke();
    };

    function getClickOrTouchPositionRelativeToCanvas(nativeEvent) {

        if (nativeEvent instanceof MouseEvent) {
            return {x:nativeEvent.offsetX, y:nativeEvent.offsetY};
        }

        else if (nativeEvent instanceof TouchEvent) {
            var rect = canvasRef.current.getBoundingClientRect();
            const touchPositionOverCanvas = nativeEvent.targetTouches[0];

            return {
                x:touchPositionOverCanvas.clientX - rect.left,
                y:touchPositionOverCanvas.clientY - rect.top
            };
        }

        else {
            return {x:null, y:null};
        }
    }

    return (
        <div className="pizarra" ref={wrapperRef}>
            <canvas
                onTouchStart={startDrawing}
                onTouchMove={draw}
                onTouchEnd={onMouseUp}
                onMouseDown={startDrawing}
                onMouseUp={onMouseUp}
                onMouseMove={draw}
                onMouseEnter={onMouseEnter}
                ref={canvasRef}
                />
        </div>
    );
}

export default PizarraSimple;
