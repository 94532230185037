import { useEffect, useState } from "react";
import PizarraSimple from "./PizarraSimple";
import BotoneraPizarra from "./BotoneraPizarra";

import papelera from "../img/papelera.svg";
import cerrar from "../img/cerrar.png";

export default function Paneles({ datosPaneles, closeBtnHandler, eliminarPanelArray }) {
	const [currentActionForPizarra, setCurrentActionForPizarra] =
		useState("DRAW");
	const [colorForPizarra, setColorForPizarra] = useState("#212121");
	const [miImagen,setMiImagen] = useState(null);

	const setEraser = function () {
		setCurrentActionForPizarra("ERASER");
	};

	const setColor = function (color) {
		setCurrentActionForPizarra("DRAW");
		setColorForPizarra(color);
	};

	const closeModalOrgClickHandler = function (ev) {
		window.localStorage.setItem(datosPaneles.id, miImagen);
		closeBtnHandler(miImagen);
	};

	const confirmarBorrar = function () {
		const preguntaConfirmacion = "¿Seguro que quieres borrar?"
		if (confirm(preguntaConfirmacion) == true) {setMiImagen(null)};
	};

	useEffect(()=>{
		const imagen =window.localStorage.getItem(datosPaneles.id);
		if(imagen!==null) setMiImagen(imagen)
	},[])

	return (
		<div
			id="popUpPizarraPaneles"
			style={{ backgroundImage: `url(${datosPaneles.imagen})` }}
		>
			<div className="contenedorPizarraYBotonera">
				<div className="contenedorPizarra">
					<PizarraSimple
						currentAction={currentActionForPizarra}
						drawingColor={colorForPizarra}
						onChange={setMiImagen}
						value={miImagen}
					/>
					<img
						src={papelera}
						className="papeleraPizarra"
						onClick={confirmarBorrar}
					/>
				</div>
				<BotoneraPizarra
					colores={["#212121", "#FF0004", "#F39200", "#7AB3E2", "#95C11E"]}
					setEraser={setEraser}
					setDrawingColor={setColor}
					currentAction={currentActionForPizarra}
					currentColor={colorForPizarra}
				/>
				<img
					src={cerrar}
					alt=""
					id="closeWidget"
					onClick={closeModalOrgClickHandler}
				/>
			</div>
		</div>
	);
}
