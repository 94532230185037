import { Route, Routes, HashRouter } from "react-router-dom";
import React from "react";

import Menu from "./Menu";
import Organizadores from "./Organizadores";

const PanelCategorias = React.lazy(() => import('./PanelCategorias'));
const IndiceLetras = React.lazy(() => import("para-escribir").then(module => ({ default: module.IndiceLetras })));
const ParaEscribirLetra = React.lazy(() => import("./ParaEscribirLetra"));
const IndiceJuegos = React.lazy(() => import("juegos").then(module => ({ default: module.IndiceJuegos })));
const PantallaConfig = React.lazy(() => import("juegos").then(module => ({ default: module.PantallaConfig })));
const Juego = React.lazy(() => import("./Juego"));

export default function App() {
	return (
		<HashRouter>
			<Routes>
				<Route path="/" element={<Menu />}>
					<Route index element={<Organizadores />} />
					<Route path="paneles" element={<PanelCategorias />} />

					<Route path="/escribir">
						<Route index element={<IndiceLetras />} />
						<Route path=":letra" element={<ParaEscribirLetra />} />
					</Route>

					<Route path="/juegos">
						<Route index element={<IndiceJuegos />} />
						<Route path="config" element={<PantallaConfig />} />
						<Route path=":juego" element={<Juego />} />
					</Route>
				</Route>
			</Routes>
		</HashRouter>
	);
}
