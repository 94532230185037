import React, {Suspense} from "react";
import { Outlet, Link, useLocation } from "react-router-dom";

export default function Menu() {
	const location = useLocation();
	const { pathname } = location;
	const splitLocation = pathname.split("/");
	
	return (
		<>
		  <nav className="menu-docente">
				<div className="logoUniverso"></div>
				<div className="contenedorEnlaces">
					<div className={splitLocation[1] === "" ? "contenedorOrganizadores active" : "contenedorOrganizadores"}><Link to="/">Organizadores</Link></div>
					<div className={splitLocation[1] === "paneles" ? "contenedorPaneles active" : "contenedorPaneles"}><Link to="/paneles">Panel de categorías</Link></div>
					<div className={splitLocation[1] === "escribir" ? "contenedorParaEscribir active" : "contenedorParaEscribir"}><Link to="/escribir">Para escribir</Link></div>
					<div className={splitLocation[1] === "juegos" ? "contenedorJuegos active" : "contenedorJuegos"}><Link to="/juegos">Juegos</Link></div>
				</div>
		  </nav>

			<Suspense fallback={<div>Cargando...</div>}>
		  		<Outlet />
			</Suspense>
		</>
	  )
}
