function BotoneraPizarra({colores, setEraser, setDrawingColor,currentAction, currentColor}) {
    const onGomaClickHandler = (ev)=>{
        setEraser();
    }

    const seleccionado = (currentAction === "ERASER") ? "goma" : currentColor

    const estiloGoma = {}
    if (seleccionado == "goma") {
        estiloGoma.transform = "scale(1.2)";
    }

    return (
        <div className="botoneraPizarra">
            <div className="boton goma" onClick={onGomaClickHandler} style={estiloGoma}></div>
            {colores.map((color) => {
                const miEstilo = {backgroundColor: color};

                if (seleccionado == color) {
                    miEstilo.transform = "scale(1.2)";
                }

                const onColorClickHandler = (ev) => {
                    setDrawingColor(color);
                }

                return (
                    <div key={color} className="boton" onClick={onColorClickHandler} style={miEstilo}/>
                );
            })}
        </div>
    );
}

export default BotoneraPizarra;
